import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  AbstractObjectSettleDialogComponent,
  adjustSettlementDate,
  DocumentSearchService,
  enumToOptions,
  isOwnDocumentObject,
  SettlementTabState,
  VisibleClassifiersMode
} from '|shared';
import {FileForm, SettlementMethod, SettlementType} from '|api/commons';
import {
  ApiFileService,
  ApiSettlementService,
  ClassificationSchemeDto,
  DocumentAllowableObjectClass,
  FileDto,
  FilePrepareCompletionDto,
  SettlementDto
} from '|api/document';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {debounceTime, map, startWith} from 'rxjs/operators';
import {EntityClassDto} from '|api/codebook';
import {IczOnChanges, IczSimpleChanges, LoadingIndicatorService} from '@icz/angular-essentials';
import {getStartOfTheDay, IczFormControl, IczFormGroup, IczOption, IczValidators} from '@icz/angular-form-elements';
import {FilterOperator} from '@icz/angular-table';
import {
  AbstractFileSettleFormComponent
} from '|modules/documents/modules/document-shared/components/file-settle-dialog/abstract-file-settle-form.component';

interface DocumentForSettlementData {
  objectClass: DocumentAllowableObjectClass,
  refNumber: Nullable<string>,
  settlementId: Nullable<number>,
}

@Component({
  selector: 'icz-file-settle-form',
  templateUrl: './file-settle-form.component.html',
  styleUrls: ['./file-settle-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileSettleFormComponent extends AbstractFileSettleFormComponent implements OnInit, IczOnChanges {

  protected loadingService = inject(LoadingIndicatorService);
  private documentsSearchService = inject(DocumentSearchService);
  private apiSettlementService = inject(ApiSettlementService);
  private apiFileService = inject(ApiFileService);

  @Input()
  file!: FileDto;
  @Input()
  showFileInfo = false;
  @Input()
  entityClasses: EntityClassDto[] = [];
  @Input({required: true})
  settlementTabState!: SettlementTabState;
  @Output()
  settlementValid = new EventEmitter<boolean>();
  @Output()
  settlementTabPrepareChange = new EventEmitter<SettlementTabState>();

  doCloseFile = true;

  readonly VisibleClassifiersMode = VisibleClassifiersMode;

  fileSettlementTypeOptions: IczOption[] = enumToOptions('settlementType', SettlementType);
  fileFormOptions = enumToOptions('fileForm', FileForm);

  minSettlementDate: Date = new Date();
  maxSettlementDate = new Date();
  isValidSettlementDate = {
    validationFn: (date: Date) => this.isDateValid(date),
    invalidDateMessage : { errorMessageTemplate: 'Datum nesmí být starší než datum založení spisu'}
  };

  documentOptions: IczOption<number, DocumentForSettlementData>[] = [];
  settlementDate!: Date;

  static prepareEntityClasses(settlementDetails: FilePrepareCompletionDto, form: IczFormGroup, classificationSchemeDetails: ClassificationSchemeDto) {
    form.get('classificationSchemeName')!.setValue(classificationSchemeDetails!.name);
    form.get('classificationSchemeValidFrom')!.setValue(classificationSchemeDetails!.validFrom);
    form.get('classificationSchemeValidTo')!.setValue(classificationSchemeDetails!.validTo);
    form.get('entityClassId')!.setValue(settlementDetails.entityClassId);
  }

  get isSettlementByRecord() {
    return this.form.get('fileSettlementType')!.value === SettlementType.BY_RECORD;
  }

  get showDocumentSelector() {
    const settlementType = this.form.get('fileSettlementType')!.value;
    return settlementType === SettlementType.BY_DOCUMENT || settlementType === SettlementType.BY_ASSIGNMENT;
  }

  private isDateValid(date: Date) {
    if (!(date && this.minSettlementDate)) return false;
    return !(date < this.minSettlementDate || date > this.maxSettlementDate);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.initFormControlListeners();
    if (this.showDocumentSelector) {
      this.findAvailableDocumentsForSettlement();
    }

    this.form.valueChanges.pipe(
      debounceTime(250),
      startWith(this.form.value),
      takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
      this.settlementValid.emit(this.form.valid);
    });
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.file && changes.file.currentValue) {
      this.minSettlementDate = getStartOfTheDay(changes.file.currentValue.auditInfo!.createdAt);
      this.form.get('fileForm')!.setValue(changes.file.currentValue.fileForm);
      this.settlementDate = new Date(this.form.get('settlementDate')!.value);
    }

    if (changes.disposalSchedulePrepare && changes.disposalSchedulePrepare.currentValue) {
      this.fillDisposalScheduleSectionWithPrepare(changes.disposalSchedulePrepare.currentValue);
    }
  }

  private findAvailableDocumentsForSettlement() {
    this.loadingService.doLoading(
      this.documentsSearchService.findAllDocumentsInFile({
        filter: [{
          fieldName: 'fileId',
          value: String(this.file.id),
          operator: FilterOperator.equals,
        }],
        sort: [{
          fieldName: 'documentPutInGivenFileAt',
          descending: true,
        }]
      }, this.file.id),
      this
    ).pipe(
      map(page => page.content.map(entry => ({
        value: entry.id,
        label: entry.subject,
        data: {
          objectClass: entry.objectClass,
          refNumber: entry.refNumber,
          settlementId: entry.settlementId
        },
      })))
    ).subscribe(documentOptions => {
      const settlementIds = documentOptions
        .filter(op => !isNil(op.data.settlementId))
        .map(op => op.data.settlementId);
      if (settlementIds.length) {
        this.apiSettlementService.settlementFindSettlementsByIds({
          body: {
            ids: settlementIds as number[],
          }
        }).subscribe(settlements => {
          if (settlements.length) {
            this.documentOptions = documentOptions.filter(op => !this.isInitialSettlementDocument(op.data.settlementId, op.value, settlements));
          }
        });
      } else {
        this.documentOptions = documentOptions;
      }

      const fileInternalOwnDocuments = this.documentOptions.filter(
        o => isOwnDocumentObject({id: o.value, objectClass: o.data!.objectClass})
      );

      if (fileInternalOwnDocuments.length > 0) {
        const lastInternalDocumentId = fileInternalOwnDocuments[0].value;
        this.form.get('relatedDocumentId')!.setValue(lastInternalDocumentId);
      }
    });
  }

  private isInitialSettlementDocument(settlementId: Nullable<number>, documentId: number, settlements: SettlementDto[]) {
    if (settlementId) {
      const settlement = settlements.find(s => s.id === settlementId);
      return settlement!.documentId === documentId && settlement!.method === SettlementMethod.INDIVIDUAL && settlement!.type === SettlementType.BY_DOCUMENT;
    } else {
      return false;
    }
  }

  updateAllowedDisposalSchedules(settlementDate: string, entityClassId: number) {
    this.loadingService.doLoading(
      this.apiFileService.fileGetValidDisposalSchedules({
        body: {
          inner: [{
            entityClassId,
            entityId: this.file.id
          }],
          referenceDate: settlementDate
        }
      }),
      this
    ).subscribe(validSchedule => {
      if (validSchedule.resultsPerEntity) {
        this.fillDisposalScheduleSectionWithPrepare(validSchedule.resultsPerEntity[0]);
        this.disposalSchedulePrepare = validSchedule.resultsPerEntity[0];
      }
    });
  }

  private initFormControlListeners() {
    // it works, but fires requests twice, find the other listener
    this.form.get('settlementDate')!.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(settlementDate => {
      if (settlementDate) {
        this.settlementDate = new Date(settlementDate);

        this.loadingService.doLoading(
          this.apiFileService.filePrepareSettlement({
            dateTime: adjustSettlementDate(settlementDate),
            id: this.file.id,
          }),
          this
        ).subscribe(filePrepare => {
          const settlementDetails = filePrepare as FilePrepareCompletionDto;
          FileSettleFormComponent.prepareEntityClasses(settlementDetails, this.form, filePrepare.classificationScheme!);
          if (settlementDetails.entityClassId) {
            this.updateAllowedDisposalSchedules(settlementDate, settlementDetails.entityClassId);
          }
        });
      }
    });

    this.form.get('doCloseFile')!.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(value => {
      this.doCloseFile = Boolean(value);

      if (value) {
        this.form.get('closeDate')!.setValue(new Date().toISOString());
        this.disposalScheduleIdCtrl!.setValidators([IczValidators.required()]);
        this.disposalScheduleIdCtrl!.enable();
      } else {
        this.disposalScheduleIdCtrl!.clearValidators();
        this.disposalScheduleIdCtrl!.disable();
      }
      this.disposalScheduleIdCtrl!.updateValueAndValidity();
    });

    this.form.get('fileSettlementType')!.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(fileSettlementType => {
      const settlingDocumentControl = this.form.get('relatedDocumentId')!;

      if (fileSettlementType === SettlementType.BY_DOCUMENT || fileSettlementType === SettlementType.BY_ASSIGNMENT) {
        settlingDocumentControl.setValidators([IczValidators.required()]);
        this.findAvailableDocumentsForSettlement();
      }
      else {
        settlingDocumentControl.setValidators([]);
      }

      AbstractObjectSettleDialogComponent.processReasonAndContentFields(
        fileSettlementType,
        this.form.get('reason') as IczFormControl,
        this.form.get('content') as IczFormControl
      );
    });

    this.form.get('entityClassId')!.valueChanges.pipe(
      startWith(this.form.get('entityClassId')!.value),
      takeUntilDestroyed(this.destroyRef))
      .subscribe(eClassId => {
        if (eClassId && this.entityClasses.length) {
          const entityClass = this.entityClasses.find(ec => ec.id === eClassId)!;
          const classificationScheme = this.classificationSchemes.find(c => c.id === entityClass.classificationSchemeId);
          if (classificationScheme) {
            this.form.get('classificationSchemeName')!.setValue(classificationScheme.name);
            this.form.get('classificationSchemeValidFrom')!.setValue(classificationScheme.validFrom);
            this.form.get('classificationSchemeValidTo')!.setValue(classificationScheme.validTo);
          }
          const settlementDate = (new Date(this.form.get('settlementDate')!.value!)).toISOString();
          if (!this.settlementTabState.prepared) {
            this.updateAllowedDisposalSchedules(settlementDate, eClassId);
            this.settlementTabPrepareChange.emit({prepared: true, classificationScheme});
          }
        }
      });
  }

}
