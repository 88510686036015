<div class="row gap-32" [formGroup]="form">
  <icz-section class="grow" label="Popis jednotky">
    <icz-form-field formControlName="name" label="Název ukládací jednotky"></icz-form-field>
    <icz-form-field formControlName="description" label="Popis ukládací jednotky"></icz-form-field>
    <icz-form-autocomplete formControlName="storageUnitForm" label="Forma" [options]="storageUnitFormOptions"></icz-form-autocomplete>
    <icz-form-field formControlName="storageUnitNumber" label="Číslo ukládací jednotky"></icz-form-field>
    <icz-form-field formControlName="physicalLocation" label="Fyzické uložení ukládací jednotky"></icz-form-field>
  </icz-section>
  <div class="advanced-metadata-column">
    <icz-section class="grow" label="Skartační režim a věcná skupina">
      <icz-entity-class-selector [form]="form" controlName="entityClassId"></icz-entity-class-selector>
      <icz-disposal-schedule-with-retention-trigger-form-fragment
        [form]="form"
        [withVisibleDisposalYear]="false"
        [writeValuesToForm]="true"
        [isDisposalYearRequired]="applicationConfigService.requireDisposalYearConsistency"
      ></icz-disposal-schedule-with-retention-trigger-form-fragment>
      <icz-form-field
        class="grow"
        label="Rok vyřazení"
        formControlName="disposalYear"
      ></icz-form-field>
    </icz-section>
    <icz-section label="Jaké objekty lze do ukládací jednotky vkládat?">
      <icz-form-autocomplete
        formControlName="enforceStrictDisposalSchedule"
        label="Ukládací jednotka jen pro shodné skartační režimy"
        [options]="disposalScheduleEnforcementOptions"
      >
        <icz-icon suffix svgIcon="info" size="small" iczTooltip="fe.ui.enforceStrictDisposalSchedule.helpTooltip"></icz-icon>
      </icz-form-autocomplete>
      <icz-checkbox formControlName="enforceStrictDisposalYear" label="Ukládací jednotka jen pro shodné roky vyřazení" class="mb-8"></icz-checkbox>
      <icz-checkbox formControlName="enforceStrictEntityClass" label="Ukládací jednotka jen pro shodné věcné skupiny" class="mb-8"></icz-checkbox>
    </icz-section>
  </div>
</div>
