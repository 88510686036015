import {Component, inject, Input, OnInit} from '@angular/core';
import {DocumentSearchService, DocumentView, EsslComponentDto} from '|shared';
import {
  FileDetailContentDatasource
} from '../../../../file-detail/components/file-detail-content/file-detail-content.datasource';
import {DocumentDto} from '|api/document';
import {
  FileSettleDocumentDetailDialogData,
  FileSettleDocumentDetailDialogResult
} from '|modules/documents/modules/document-shared/components/file-settle-dialog/file-settle-model';
import {
  FileSettleDocumentDetailDialogComponent
} from '|modules/documents/modules/document-shared/components/file-settle-dialog/file-settle-document-detail/file-settle-document-detail-dialog.component';
import {DocumentState} from '|api/commons';
import {IczFormArray, IczFormGroup} from '@icz/angular-form-elements';
import {IczModalService} from '@icz/angular-modal';


@Component({
  selector: 'icz-file-settle-file-content',
  templateUrl: './file-settle-file-content.component.html',
  styleUrls: ['./file-settle-file-content.component.scss'],
})
export class FileSettleFileContentComponent implements OnInit {

  private searchService = inject(DocumentSearchService);
  private iczModalService = inject(IczModalService);

  @Input({required: true}) fileId!: number;
  @Input({required: true}) settlementDate!: Date;
  @Input({required: true}) unsettledDocuments!: IczFormArray;
  dataSource!: FileDetailContentDatasource;

  selectedRows: DocumentDto[] = [];

  readonly DocumentView = DocumentView;

  openComponentSelectionPerDocument(selectedRows: DocumentDto[]) {
    if (selectedRows.length !== 1) return;

    let selectedDocumentFormGroup: Nullable<IczFormGroup>;
    let selectedDocumentPreselectedComponents = [];
    let selectedDocumentRefNumber = '';
    let selectedDocumentSubject = '';
    let selectedDocumentDocumentTypeId: Nullable<number>;

    const documentsFormValue: Array<any> = this.unsettledDocuments.getRawValue();
    const selectedDocumentIndex = documentsFormValue.findIndex(value => {
      return value.documentId === this.selectedRows[0].id;
    });

    if (!isNil(selectedDocumentIndex)) {
      selectedDocumentFormGroup = this.unsettledDocuments.controls[selectedDocumentIndex] as IczFormGroup;
      const selectedDocumentPreviouslyExcludedComponentIds = selectedDocumentFormGroup.value.excludedComponentIds!;
      selectedDocumentRefNumber = selectedDocumentFormGroup.value.documentRefNumber;
      selectedDocumentSubject = selectedDocumentFormGroup.value.documentSubject;
      selectedDocumentDocumentTypeId = selectedDocumentFormGroup.value.documentTypeId;
      selectedDocumentPreselectedComponents = selectedDocumentFormGroup.value.components.filter((c: EsslComponentDto) => !selectedDocumentPreviouslyExcludedComponentIds.includes(c.id!));
    }

    this.iczModalService.openComponentInModal<FileSettleDocumentDetailDialogResult, FileSettleDocumentDetailDialogData>({
      component: FileSettleDocumentDetailDialogComponent,
      modalOptions: {
        width: 1000,
        height: '80vh',
        titleTemplateContext: {documentRefNumber: selectedDocumentRefNumber, documentSubject: selectedDocumentSubject},
        titleTemplate: 'Výběr komponent pro vyřízení dokumentu {{documentRefNumber}} - {{documentSubject}}',
      },
      data: {
        documentId: this.selectedRows[0].id,
        settlementDate: this.settlementDate,
        documentTypeId: selectedDocumentDocumentTypeId,
        preselectedComponents: selectedDocumentPreselectedComponents,
      },
    }).subscribe((result: Nullable<FileSettleDocumentDetailDialogResult>) => {
      if (result && selectedDocumentFormGroup) {
        selectedDocumentFormGroup.get('documentTypeId')!.setValue(result.documentTypeId);
        selectedDocumentFormGroup.get('excludedComponentIds')!.setValue(result.excludedComponentIds);
      }
    });
  }

  ngOnInit() {
    if (this.fileId) {
      this.dataSource = new FileDetailContentDatasource(this.searchService, this.fileId, [DocumentState.IN_PROGRESS]);
    }
  }

}
