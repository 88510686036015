import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {
  CheckUnsavedFormDialogService,
  CodebookService,
  esslErrorDtoToToastParameters,
  IFormGroupCheckable,
  isDocumentObject,
  isValidNow,
  namedDtosToOptions,
  RegistryOfficeToastService,
  RegistryOfficeToastType
} from '|shared';
import {
  ApiRegistryOfficeTransferService,
  DocumentDto,
  FileDto,
  RegistryOfficeTransferCreateDto,
  StorageUnitDto
} from '|api/document';
import {TranslateService} from '@ngx-translate/core';
import {EntityType} from '|api/commons';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {IczFormControl, IczFormGroup, IczOption, IczValidators} from '@icz/angular-form-elements';
import {injectModalData, injectModalRef} from '@icz/angular-modal';
import {map} from 'rxjs';

export type RegistryOfficeTransferrableObject = FileDto | DocumentDto | StorageUnitDto;

@Component({
  selector: 'icz-transfer-to-registry-office-dialog',
  templateUrl: './transfer-to-registry-office-dialog.component.html',
  styleUrls: ['./transfer-to-registry-office-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CheckUnsavedFormDialogService
  ]
})
export class TransferToRegistryOfficeDialogComponent implements OnInit, IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<boolean>>();
  private codebookService = inject(CodebookService);
  private apiRegistryOfficeTransferService = inject(ApiRegistryOfficeTransferService);
  protected checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private registryOfficeToastService = inject(RegistryOfficeToastService);
  private translateService = inject(TranslateService);
  protected selection = injectModalData<RegistryOfficeTransferrableObject[]>();

  form = new IczFormGroup({
    registryOfficeId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
  });

  formGroupsToCheck!: string[];
  registryOfficeOptions: IczOption[] = [];

  ngOnInit(): void {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);

    this.loadingService.doLoading(
      this.codebookService.transferableRegistryOfficesForCurrentFunctionalPosition(),
      this
    ).pipe(
      map(registryOffices => registryOffices.filter(isValidNow)),
      namedDtosToOptions
    ).subscribe(offices => {
      this.registryOfficeOptions = offices;
    });
  }

  submit() {
    const body: RegistryOfficeTransferCreateDto = {
      registryOfficeId: this.form.get('registryOfficeId')!.value!,
      transferEntities: this.selection.map(s => ({
        entityId: s.id!,
        entityType: isDocumentObject(s) ? EntityType.DOCUMENT : s.entityType!
      }))
    };

    this.loadingService.doLoading(
      this.apiRegistryOfficeTransferService.registryOfficeTransferTransferCreate({
        body
      }),
      this
    ).subscribe({
      next: _ => {
        this.form.markAsPristine();
        this.modalRef.close(true);
      },
      error: err => {
        this.registryOfficeToastService.dispatchErrorToast(
          RegistryOfficeToastType.RO_TRANSFER_CREATE_ERROR,
          esslErrorDtoToToastParameters(this.translateService, err.error),
        );
      }
    });
  }

  cancel() {
    this.modalRef.close(null);
  }

}
