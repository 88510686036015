<form [formGroup]="form">
  <div class="gap-24 row grow">
    <div class="gap-8 col grow">
      <icz-section label="Jak chcete vyřídit">
        <icz-form-autocomplete [options]="generalTabFileSettlementTypeOptions" formControlName="fileSettlementType"
                               label="Způsob vyřízení spisu"></icz-form-autocomplete>
        <icz-date-picker [selectableDates]="isBulkValidSettlementDate" class="grow"
                         formControlName="settlementDate"
                         label="Datum vyřízení"
                         outputFormat="dateTime">
          <icz-icon size="small" suffix
                    tooltipText="Termín vyřízení nesmí být před datumem vzniku spisu.">
          </icz-icon>
        </icz-date-picker>
        @if (isBulkSettlementByRecord) {
          <div>
            <icz-form-field class="grow" formControlName="reason" label="Důvod"></icz-form-field>
            <icz-form-field class="grow" formControlName="content" label="Obsah"></icz-form-field>
          </div>
        }
        @if (isBulkSettlementByDocument) {
          <div>
            <icz-alert heading="Jako vyřizující dokument budou ve spisech vybrány naposledy vložené vlastní dokumenty." severity="info"></icz-alert>
          </div>
        }
      </icz-section>
      <icz-section label="Uzavření spisu">
        <div class="grow">
          <icz-checkbox formControlName="doCloseFile"
                        label="Společně s vyřízením spis také uzavřít"></icz-checkbox>
          <br>
        </div>
        @if (form.get('doCloseFile')!.value) {
          <icz-date-picker class="grow"
                           formControlName="closeDate" label="Datum uzavření" outputFormat="dateTime"></icz-date-picker>
        }
      </icz-section>
    </div>
    <div class="gap-8 col grow">
      <icz-section label="Přenastavit hromadně pro všechny vyřizované spisy">
        <icz-checkbox formControlName="useGlobalSettings"
                      label="Pro všechny vyřizované spisy přenastavit věcnou skupinu nebo typ spisu"></icz-checkbox>
        @if (form.get('useGlobalSettings')!.value) {
          <icz-file-type-selector
            [date]="bulkSettlementDate"
            [form]="form"
            [mode]="VisibleClassifiersMode.VALID_AT_GIVEN_DATE"
            controlName="fileTypeId"
          ></icz-file-type-selector>
          <icz-entity-class-selector
            [form]="form"
            [showEntityClassesValidAt]="bulkSettlementDate"
            controlName="entityClassId"
          ></icz-entity-class-selector>
          <div class="row grow">
            <icz-form-field class="grow" formControlName="classificationSchemeName" label="Spisový plán"></icz-form-field>
          </div>
          <div class="row grow">
            <icz-date-picker class="grow" formControlName="classificationSchemeValidFrom" label="Platnost spisového plánu od" outputFormat="dateTime"></icz-date-picker>
            <icz-date-picker class="grow" formControlName="classificationSchemeValidTo" label="Platnost spisového plánu do" outputFormat="dateTime"></icz-date-picker>
          </div>
        }
      </icz-section>
    </div>
  </div>
  <div class="row grow">
    <div class="col grow">
      @if (form.get('useGlobalSettings')!.value) {
        <icz-section class="grow" label="Skartační režim">
          <div class="gap-24 row grow">
            <div class="col grow">
              <icz-form-autocomplete
                [clearable]="false"
                [options]="disposalScheduleOptions"
                class="grow"
                formControlName="disposalScheduleId"
                label="Skartační režim"
              ></icz-form-autocomplete>
              <icz-form-autocomplete [options]="disposalOperationCodeOptions" class="grow-2"
                                     formControlName="disposalOperationCode" label="Skartační operace"></icz-form-autocomplete>
              <icz-form-field class="grow-1" formControlName="retentionPeriod" label="Skartační lhůta"></icz-form-field>
              <icz-form-autocomplete
                [clearable]="false"
                [options]="retentionTriggerTypesOptions"
                class="grow"
                formControlName="retentionTriggerTypeCode"
                label="Spouštěcí událost"
              ></icz-form-autocomplete>
              @if (disposalSchedulePrepare && isExternalRetentionTriggerTypeWithPeriod()) {
                <icz-ext-retention-trigger-selector
                  [form]="form"
                  [availableExternalRetentionTriggerIds]="disposalSchedulePrepare.externalRetentionTriggerIds ?? []"
                ></icz-ext-retention-trigger-selector>
              }
            </div>
            <div class="col grow">
              <div class="row">
                <icz-form-field class="grow" label="Rok spouštěcí události"  formControlName="yearOfRetentionPeriodStart" type="integer"></icz-form-field>
                @if (isRetentionTriggerTypeWithPeriod()) {
                  <icz-form-field class="grow" label="Rok kontroly spouštěcí události"  formControlName="triggerEventCheckYear" type="integer"></icz-form-field>
                } @else {
                  <div class="grow"></div>
                }
              </div>
            </div>
          </div>
        </icz-section>
      }
    </div>
  </div>
</form>
