import {ChangeDetectionStrategy, Component, inject, Input, OnInit} from '@angular/core';
import {
  namedDtoToOption,
  VisibleClassifiersMode
} from '|shared';
import {ApiFileService, FilePrepareCompletionFileTypeDto} from '|api/document';
import {EntityClassDto, SecurityCategoryDto} from '|api/codebook';
import {
  AbstractFileSettleFormComponent
} from '|modules/documents/modules/document-shared/components/file-settle-dialog/abstract-file-settle-form.component';
import {IczOnChanges, IczSimpleChanges, LoadingIndicatorService} from '@icz/angular-essentials';
import {IczOption} from '@icz/angular-form-elements';
import {IczInMemoryDatasource} from '@icz/angular-table';

@Component({
  selector: 'icz-general-file-close-form',
  templateUrl: './general-file-close-form.component.html',
  styleUrls: ['./general-file-close-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralFileCloseFormComponent extends AbstractFileSettleFormComponent implements OnInit, IczOnChanges {

  protected loadingService = inject(LoadingIndicatorService);
  private apiFileService = inject(ApiFileService);

  @Input()
  availableEntityClasses: Nullable<EntityClassDto[]>;
  @Input()
  availableFileTypes?: Nullable<FilePrepareCompletionFileTypeDto[]>;
  @Input()
  allEntityClassesDataSource = new IczInMemoryDatasource(() => []);

  securityCategories: SecurityCategoryDto[] = [];
  entityClasses: EntityClassDto[] = [];
  fileTypeOptions: IczOption[] = [];
  entityClassOptions: IczOption[] = [];
  validEntityClassesDataSource = new IczInMemoryDatasource(() => []);

  formGroupsToCheck!: string[];

  readonly VisibleClassifiersMode = VisibleClassifiersMode;

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes?.availableEntityClasses?.currentValue) {
      this.entityClassOptions = this.availableEntityClasses!.map(ec => namedDtoToOption()(ec));
      this.validEntityClassesDataSource = new IczInMemoryDatasource(() => this.availableEntityClasses!);
    }
    if (changes?.availableFileTypes?.currentValue) {
      this.fileTypeOptions = this.availableFileTypes!.map(ft => {return {value: ft.id!, label: ft.label!};});
    }

    if (changes.disposalSchedulePrepare && changes.disposalSchedulePrepare.currentValue) {
      this.fillDisposalScheduleSectionWithPrepare(changes.disposalSchedulePrepare.currentValue);
    }
  }
}
