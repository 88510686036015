<div class="h-full">
  <form [formGroup]="form" [waiting]="loadingService.isLoading$(this) | async" >
    <div class="col grow mb-16">
      @if (!isBulkOperation) {
        <icz-entity-info [entity]="data.selectedObjects[0]"></icz-entity-info>
      }
    </div>
    <div class="row">
      <div class="col grow">
        @if (data.editCheckYear) {
          <icz-form-field class="grow" label="Rok kontroly spouštěcí události"  formControlName="triggerEventCheckYear" type="integer"></icz-form-field>
        } @else {
          <icz-form-field class="grow" label="Rok spouštěcí události"  formControlName="yearOfRetentionPeriodStart" type="integer"></icz-form-field>
        }
      </div>
      <div class="col grow"></div>
    </div>
  </form>
</div>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit
              [disabled]="loadingService.isLoading(this)"
              (onAction)="submit()"
              primary label="Upravit"
  ></icz-button>
  <icz-button rightButtons
              [disabled]="loadingService.isLoading(this)"
              (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
