import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {namedDtoToOption, VisibleClassifiersMode} from '|shared';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  ApiFileService,
  FileDto,
  FilePrepareCompletionFileTypeDto
} from '|api/document';
import {EntityClassDto, SecurityCategoryDto} from '|api/codebook';
import {debounceTime, startWith} from 'rxjs/operators';
import {IczOnChanges, IczSimpleChanges, LoadingIndicatorService} from '@icz/angular-essentials';
import {IczOption} from '@icz/angular-form-elements';
import {IczInMemoryDatasource} from '@icz/angular-table';
import {
  AbstractFileSettleFormComponent
} from '|modules/documents/modules/document-shared/components/file-settle-dialog/abstract-file-settle-form.component';

@Component({
  selector: 'icz-file-close-form',
  templateUrl: './file-close-form.component.html',
  styleUrls: ['./file-close-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileCloseFormComponent extends AbstractFileSettleFormComponent implements OnInit, IczOnChanges {

  protected loadingService = inject(LoadingIndicatorService);
  private apiFileService = inject(ApiFileService);

  @Input({required: true})
  file!: FileDto;
  @Input()
  availableEntityClasses: Nullable<EntityClassDto[]>;
  @Input()
  availableFileTypes?: Nullable<FilePrepareCompletionFileTypeDto[]>;
  @Input()
  settlementDate!: Date;
  @Input()
  showFileInfo = false;
  @Output()
  closeValid = new EventEmitter<boolean>();
  securityCategories: SecurityCategoryDto[] = [];
  entityClasses: EntityClassDto[] = [];
  fileTypeOptions: IczOption[] = [];
  entityClassOptions: IczOption[] = [];
  validEntityClassesDataSource = new IczInMemoryDatasource(() => []);

  formGroupsToCheck!: string[];

  readonly VisibleClassifiersMode = VisibleClassifiersMode;

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes?.availableEntityClasses?.currentValue) {
      this.entityClassOptions = this.availableEntityClasses!.map(ec => namedDtoToOption()(ec));
      this.validEntityClassesDataSource = new IczInMemoryDatasource(() => this.availableEntityClasses!);
    }
    if (changes?.availableFileTypes?.currentValue) {
      this.fileTypeOptions = this.availableFileTypes!.map(ft => {return {value: ft.id!, label: ft.label!};});
    }

    if (changes.disposalSchedulePrepare && changes.disposalSchedulePrepare.currentValue) {
      this.fillDisposalScheduleSectionWithPrepare(changes.disposalSchedulePrepare.currentValue);
    }
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.form.valueChanges.pipe(debounceTime(250), takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
      this.closeValid.emit(this.form.valid);
    });

    this.form.get('entityClassId')?.valueChanges.pipe(
      startWith(this.form.get('entityClassId')!.value),
      takeUntilDestroyed(this.destroyRef))
      .subscribe(entityClassId => {
      if (entityClassId) {
        const settlementDate = (new Date(this.form.get('closeDate')!.value!)).toISOString();
        this.loadingService.doLoading(
          this.apiFileService.fileGetValidDisposalSchedules({
            body: {
              inner: [{
                entityClassId,
                entityId: this.file.id
              }],
              referenceDate: settlementDate
            }
          }),
          this
        ).subscribe(validSchedule => {
          if (validSchedule.resultsPerEntity) {
            this.disposalSchedulePrepare = validSchedule.resultsPerEntity[0];
            this.fillDisposalScheduleSectionWithPrepare(this.disposalSchedulePrepare);
          }
        });
      }
    });
  }
}
