import {Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {ApplicationConfigService, CodebookService, DisposalTipDetailLevel, enumToOptions,} from '|shared';
import {EntityClassDto} from '|api/codebook';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {StorageUnitForm} from '|api/commons';
import {IczFormGroup, IczOption} from '@icz/angular-form-elements';

@Component({
  selector: 'icz-new-storage-unit-specification-form',
  templateUrl: './new-storage-unit-specification-form.component.html',
  styleUrls: ['./new-storage-unit-specification-form.component.scss']
})
export class NewStorageUnitSpecificationFormComponent implements OnInit {

  applicationConfigService = inject(ApplicationConfigService);
  private codebookService = inject(CodebookService);
  private destroyRef = inject(DestroyRef);

  @Input({required: true})
  form!: IczFormGroup;

  entityClasses: EntityClassDto[] = [];

  storageUnitFormOptions = enumToOptions('storageUnitForm', StorageUnitForm);
  disposalScheduleEnforcementOptions: IczOption<boolean>[] = [
    {
      value: true,
      label: 'Ano - jen pro shodný skartační režim',
    },
    {
      value: false,
      label: 'Ne - lze vložit i objekt s méně přísnou skartační operací',
    }
  ];

  readonly DisposalTipDetailLevel = DisposalTipDetailLevel;

  ngOnInit() {
    this.codebookService.entityClasses().subscribe(entityClasses => {
      this.entityClasses = entityClasses;

      const disposalScheduleControl = this.form.get('disposalScheduleId')!;

      this.form.get('entityClassId')!.valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(entityClassId => {
        const entityClass = this.entityClasses.find(ec => ec.id === entityClassId);
        const applicableDisposalScheduleId = entityClass?.disposalScheduleId;

        if (applicableDisposalScheduleId) {
          disposalScheduleControl.setValue(applicableDisposalScheduleId);
          disposalScheduleControl.disable();
        }
        else {
          disposalScheduleControl.setValue(null);
          disposalScheduleControl.enable();
        }
      });
    });
  }

}
