<form [formGroup]="form" [waiting]="loadingService.isLoading$(this) | async">
  <div class="row h-full">
    <div class="col grow gap-16">
      <icz-checkbox label="Upravit cílovou spisovnu" formControlName="showRegistryOfficeSelector"></icz-checkbox>
      @if (form.get('showRegistryOfficeSelector')!.value) {
        <icz-form-autocomplete
          formControlName="registryOfficeId"
          label="Zvolte cílovou spisovnu"
          [options]="registryOfficeOptions"
        ></icz-form-autocomplete>
      }
    </div>
  </div>
</form>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit
    [disabled]="loadingService.isLoading(this)"
    (onAction)="submit()"
    primary label="Převzít"
  ></icz-button>
  <icz-button rightButtons
    [disabled]="loadingService.isLoading(this)"
    (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
