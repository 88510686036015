import {DocumentState} from '|api/commons';
import {DocumentSearchService, DocumentsTableColumn} from '|shared';
import {SearchRecordSourceDocumentFileDto} from '|api/elastic';
import {FilterOperator, IczTableDataSource, SortParam} from '@icz/angular-table';

export class FileDetailContentDatasource extends IczTableDataSource<SearchRecordSourceDocumentFileDto> {
  constructor(searchService: DocumentSearchService, fileId: number, documentStates?: DocumentState[]) {
    super(
      searchParams => {
        const insertedDocumentsFilter = searchParams.filter.find(f => f.fieldName === DocumentsTableColumn.FILE_INSERTED_DOCUMENT);

        if (insertedDocumentsFilter) {
          const filterItemIndex = searchParams.filter.findIndex(f => f.fieldName === DocumentsTableColumn.FILE_INSERTED_DOCUMENT);

          if (insertedDocumentsFilter.value === 'true') {
            searchParams.filter[filterItemIndex].value = null;
            searchParams.filter[filterItemIndex].operator = FilterOperator.notEmpty;
          }
          else {
            searchParams.filter.splice(filterItemIndex, 1);
          }
        }

        const sortOrder: SortParam<keyof SearchRecordSourceDocumentFileDto> = {
          fieldName: 'orderNumberInGivenFile',
          descending: false
        };

        if (searchParams.sort && !searchParams.sort.find(s => s.fieldName === 'orderNumberInGivenFile')) {
          searchParams.sort = [sortOrder as SortParam<string>].concat(searchParams.sort);
        }
        else {
          searchParams.sort = [sortOrder as SortParam<string>];
        }

        if (documentStates?.length) {
          searchParams.filter = [...searchParams.filter, {fieldName: 'documentState', value: documentStates.join(',')}];
        }

        return searchService.findAllDocumentsInFile(searchParams, fileId);
      },
    );
  }
}
