@if (dataSource) {
  <icz-documents-table
    tableId="storage-unit-insert-selection"
    class="grow"
    [dataSource]="dataSource"
    [viewType]="StorageUnitView.STORAGE_UNIT_INSERT_DETAIL"
    [openQuickPreview]="false"
    [hideTableToolbarButtons]="true"
    (activeRowChanged)="itemSelected($event)"
    >
    <icz-label noDataLabel label="Žádné objekty"></icz-label>
  </icz-documents-table>
}
