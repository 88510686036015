import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {
  CheckUnsavedFormDialogService,
  DocumentToastService,
  DocumentToastType,
  esslErrorDtoToToastParameters,
  FileToastService,
  FileToastType,
  IFormGroupCheckable,
  isDocumentEntity
} from '|shared';
import {ApiSettlementService, BulkTriggerEventUpdateDto, DocumentDto, FileDto} from '|api/document';
import {EntityType} from '|api/commons';
import {InternalNotificationKey} from '|api/notification';
import {TranslateService} from '@ngx-translate/core';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {injectModalData, injectModalRef} from '@icz/angular-modal';
import {IczFormControl, IczFormGroup, IczValidators} from '@icz/angular-form-elements';

export interface EditRetentionYearDialogData {
  editCheckYear: boolean,
  selectedObjects: (DocumentDto | FileDto)[],
}

@Component({
  selector: 'icz-edit-retention-year-dialog',
  templateUrl: './edit-retention-year-dialog.component.html',
  styleUrls: ['./edit-retention-year-dialog.component.scss'],
  providers: [
    CheckUnsavedFormDialogService
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditRetentionYearDialogComponent implements OnInit, IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<boolean>>();
  protected checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private documentToastService = inject(DocumentToastService);
  private fileToastService = inject(FileToastService);
  private translateService = inject(TranslateService);
  private apiSettlementService = inject(ApiSettlementService);
  protected data = injectModalData<EditRetentionYearDialogData>();

  form = new IczFormGroup({
    yearOfRetentionPeriodStart: new IczFormControl<Nullable<number>>(null),
    triggerEventCheckYear: new IczFormControl<Nullable<number>>(null, [IczValidators.min((new Date()).getFullYear())]),
  });

  formGroupsToCheck!: string[];

  isBulkOperation = false;

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);
    this.isBulkOperation = this.data.selectedObjects.length > 1;
    if (this.data.editCheckYear) {
      this.form.get('triggerEventCheckYear')!.addValidators([IczValidators.required()]);
      this.form.get('triggerEventCheckYear')!.updateValueAndValidity();
    } else {
      this.form.get('yearOfRetentionPeriodStart')!.addValidators([IczValidators.required()]);
      this.form.get('yearOfRetentionPeriodStart')!.updateValueAndValidity();
    }
  }

  submit() {
    const formData = this.form.getRawValue();
    const reqData = this.data.editCheckYear ? {triggerEventCheckYear: formData.triggerEventCheckYear} : {yearOfRetentionPeriodStart: formData.yearOfRetentionPeriodStart};
    const requestData: BulkTriggerEventUpdateDto = {...reqData, operationEntityDtos: this.data.selectedObjects.map(obj => ({entityType: isDocumentEntity(obj.entityType!) ? EntityType.DOCUMENT : EntityType.FILE, id: obj.id!}))};

    this.loadingService.doLoading(
      this.apiSettlementService.settlementUpdateTriggerYears({body: requestData}),
      this
    ).subscribe({
      next: _ => {
        if (this.isBulkOperation) {
          this.documentToastService.dispatchBulkOperationStartedToast(this.data.editCheckYear ? DocumentToastType.BULK_RETENTION_TRIGGER_CHECK_YEAR_UPDATED : DocumentToastType.BULK_RETENTION_TRIGGER_YEAR_UPDATED, {
            [InternalNotificationKey.COUNT]: this.data.selectedObjects.length
          });
        } else {
          if (isDocumentEntity(this.data.selectedObjects[0].entityType)) {
            this.documentToastService.dispatchDocumentInfoToast(this.data.editCheckYear ? DocumentToastType.DOCUMENT_RETENTION_TRIGGER_CHECK_YEAR_UPDATED : DocumentToastType.DOCUMENT_RETENTION_TRIGGER_YEAR_UPDATED, {
              [InternalNotificationKey.DOCUMENT_ID]: this.data.selectedObjects[0].id!,
              [InternalNotificationKey.DOCUMENT_SUBJECT]: this.data.selectedObjects[0].subject
            });
          } else {
            this.fileToastService.dispatchFileInfoToast(this.data.editCheckYear ? FileToastType.FILE_RETENTION_TRIGGER_CHECK_YEAR_UPDATED : FileToastType.FILE_RETENTION_TRIGGER_YEAR_UPDATED, {
              [InternalNotificationKey.FILE_ID]: this.data.selectedObjects[0].id!,
              [InternalNotificationKey.FILE_SUBJECT]: this.data.selectedObjects[0].subject
            });
          }
        }
        this.form.markAsPristine();
        this.modalRef.close(true);
      },
      error: error => {
        const errorData = esslErrorDtoToToastParameters(this.translateService, error.error);
        if (this.isBulkOperation) {
          this.documentToastService.dispatchDocumentErrorToast(this.data.editCheckYear ? DocumentToastType.BULK_RETENTION_TRIGGER_CHECK_YEAR_UPDATE_ERROR : DocumentToastType.BULK_RETENTION_TRIGGER_YEAR_UPDATE_ERROR, errorData);
        } else {
          if (isDocumentEntity(this.data.selectedObjects[0].entityType)) {
            this.documentToastService.dispatchDocumentErrorToast(this.data.editCheckYear ? DocumentToastType.DOCUMENT_RETENTION_TRIGGER_CHECK_YEAR_UPDATE_ERROR : DocumentToastType.DOCUMENT_RETENTION_TRIGGER_YEAR_UPDATE_ERROR, errorData);
          } else {
            this.fileToastService.dispatchFileErrorToast(this.data.editCheckYear ? FileToastType.FILE_RETENTION_TRIGGER_CHECK_YEAR_UPDATE_ERROR : FileToastType.FILE_RETENTION_TRIGGER_YEAR_UPDATE_ERROR, errorData);
          }
        }
      }
    });
  }

  cancel() {
    this.modalRef.close(null);
  }

}
