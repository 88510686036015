@if (showFileInfo) {
  <div>
    <icz-section label="Informace o spisu">
      <icz-entity-info [entity]="file"></icz-entity-info>
    </icz-section>
  </div>
}

<form [formGroup]="form" [waiting]="loadingService.isLoading$(this) | async">
  <div class="gap-24 row grow">
    <div class="gap-8 col grow">
      <icz-section label="Jak chcete vyřídit">
        <icz-form-autocomplete label="Způsob vyřízení spisu" formControlName="fileSettlementType"
        [options]="fileSettlementTypeOptions"></icz-form-autocomplete>
        <icz-date-picker class="grow" outputFormat="dateTime"
          label="Datum vyřízení"
          formControlName="settlementDate"
          [selectableDates]="isValidSettlementDate">
          <icz-icon suffix size="small"
            tooltipText="Termín vyřízení nesmí být před datumem vzniku spisu.">
          </icz-icon>
        </icz-date-picker>
        @if (showDocumentSelector) {
          <div>
            <icz-form-autocomplete class="grow"
              label="Vyberte ze spisu vyřizující dokument"
              formControlName="relatedDocumentId"
              searchTermInputPrefix="Název"
              [customOptionTemplate]="optionTemplate"
              [options]="documentOptions">
            </icz-form-autocomplete>
            <ng-template #optionTemplate let-context>
              <div class="row">
                <div class="col">
                  <icz-object-class-icon [objectClass]="context.option.data.objectClass" class="mt-2 ml-8 mr-4"></icz-object-class-icon>
                </div>
                <div class="col icz-body-1">
                  <span class="ref-number">{{context.option.data.refNumber}}</span><br>
                  {{context.option.label}}
                </div>
              </div>
            </ng-template>
          </div>
        }
        @if (isSettlementByRecord) {
          <div>
            <icz-form-field class="grow" formControlName="reason" label="Důvod"></icz-form-field>
            <icz-form-field class="grow" formControlName="content" label="Obsah"></icz-form-field>
          </div>
        }
      </icz-section>
      <icz-section label="Uzavření spisu">
        <div class="grow">
          <icz-checkbox label="Společně s vyřízením spis také uzavřít"
          formControlName="doCloseFile"></icz-checkbox>
          <br>
          </div>
          @if (form.get('doCloseFile')!.value) {
            <icz-date-picker class="grow"
            label="Datum uzavření" formControlName="closeDate" outputFormat="dateTime"></icz-date-picker>
          }
        </icz-section>
      </div>
      <div class="gap-8 col grow">
        <icz-section label="Zatřídění">
          <icz-file-type-selector
            [form]="form"
            controlName="fileTypeId"
            [mode]="VisibleClassifiersMode.VALID_AT_GIVEN_DATE"
            [date]="settlementDate"
          ></icz-file-type-selector>
          <icz-entity-class-selector
            [form]="form"
            controlName="entityClassId"
            [showEntityClassesValidAt]="settlementDate"
          ></icz-entity-class-selector>
          <div class="row grow">
            <icz-form-autocomplete label="Forma spisu" formControlName="fileForm"
              [options]="fileFormOptions" class="grow">
            </icz-form-autocomplete>
          </div>
          <div class="row grow">
            <icz-form-field label="Spisový plán" formControlName="classificationSchemeName" class="grow"></icz-form-field>
          </div>
          <div class="row grow">
            <icz-date-picker label="Platnost spisového plánu od" formControlName="classificationSchemeValidFrom" class="grow" outputFormat="dateTime"></icz-date-picker>
            <icz-date-picker label="Platnost spisového plánu do" formControlName="classificationSchemeValidTo" class="grow" outputFormat="dateTime"></icz-date-picker>
          </div>
        </icz-section>
      </div>
    </div>
    <div class="row grow">
      <div class="col grow">
        <icz-section class="grow" label="Skartační režim">
          <div class="gap-24 row grow">
            <div class="col grow">
              <icz-form-autocomplete
                [clearable]="false"
                [options]="disposalScheduleOptions"
                class="grow"
                formControlName="disposalScheduleId"
                label="Skartační režim"
              ></icz-form-autocomplete>
              <icz-form-autocomplete [options]="disposalOperationCodeOptions" class="grow-2"
                                     formControlName="disposalOperationCode" label="Skartační operace"></icz-form-autocomplete>
              <icz-form-field class="grow-1" formControlName="retentionPeriod" label="Skartační lhůta"></icz-form-field>
              <icz-form-autocomplete
                [clearable]="false"
                [options]="retentionTriggerTypesOptions"
                class="grow"
                formControlName="retentionTriggerTypeCode"
                label="Spouštěcí událost"
              ></icz-form-autocomplete>
              @if (disposalSchedulePrepare && isExternalRetentionTriggerTypeWithPeriod()) {
                <icz-ext-retention-trigger-selector
                  [form]="form"
                  [availableExternalRetentionTriggerIds]="disposalSchedulePrepare.externalRetentionTriggerIds ?? []"
                ></icz-ext-retention-trigger-selector>
              }
            </div>
            <div class="col grow">
              <icz-form-field label="Nejdelší skartační lhůta ve spisu"
              formControlName="minRetentionPeriod"></icz-form-field>
              <icz-form-field label="Nejpřísnější skartační znak ve spisu"
              formControlName="minDisposalOperationCode"></icz-form-field>
              <div class="row">
                <icz-form-field class="grow" label="Rok spouštěcí události"  formControlName="yearOfRetentionPeriodStart" type="integer"></icz-form-field>
                @if (isRetentionTriggerTypeWithPeriod()) {
                  <icz-form-field class="grow" label="Rok kontroly spouštěcí události"  formControlName="triggerEventCheckYear" type="integer"></icz-form-field>
                }  @else {
                  <div class="grow"></div>
                }
              </div>
            </div>
          </div>
        </icz-section>
      </div>
    </div>
    <div class="row grow">
      <div class="col grow">
        <icz-section class="grow" label="Komentáře" testingFeature>
          <icz-checkbox class="grow" label="Smazat uživatelské komentáře"
            secondaryLabel="Spis obsahuje 4 uživatelské komentáře"
            formControlName="deleteComments">
          </icz-checkbox>
        </icz-section>
      </div>
    </div>
  </form>
