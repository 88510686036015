import {Component, inject, OnInit} from '@angular/core';
import {ApiStorageUnitService, StorageUnitDto} from '|api/document';
import {getSuperclassEntityType, StorageUnitView} from '|shared';
import {StorageUnitInsertableObject} from '../storage-unit-insert-dialog/storage-unit-insert-dialog.component';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {injectModalData, injectModalRef} from '@icz/angular-modal';
import {IczInMemoryDatasource, IczTableDataSource} from '@icz/angular-table';

export interface StorageUnitSelectorDialogData {
  objectsToInsert: StorageUnitInsertableObject[];
  isMove: boolean;
}

@Component({
  selector: 'icz-storage-unit-selector-dialog',
  templateUrl: './storage-unit-selector-dialog.component.html',
  styleUrls: ['./storage-unit-selector-dialog.component.scss'],
})
export class StorageUnitSelectorDialogComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  private modalRef = injectModalRef<Nullable<StorageUnitDto>>();
  private apiStorageUnitService = inject(ApiStorageUnitService);
  modalData = injectModalData<StorageUnitSelectorDialogData>();

  dataSource!: IczTableDataSource<StorageUnitDto>;
  readonly StorageUnitView = StorageUnitView;

  ngOnInit() {
    if (this.modalData.objectsToInsert) {
      this.apiStorageUnitService.storageUnitFindValidStorageUnitsForEntities({
        body: {
          entities: this.modalData.objectsToInsert.map(obj => ({
            id: obj.id!,
            entityType: getSuperclassEntityType(obj.entityType!),
          })),
          findForMove: this.modalData.isMove,
        },
      }).subscribe(validStorageUnits => {
        this.dataSource = new IczInMemoryDatasource(() => validStorageUnits);
      });
    }
  }

  itemSelected(selection: Nullable<StorageUnitDto>) {
    if (selection) {
      this.modalRef.close(selection);
    }
  }

}
