import {Component} from '@angular/core';
import {BusinessRuleName, DataFileFormatAcceptability} from '|api/commons';
import {EsslComponentDto, EsslComponentsTableColumnSet, VisibleClassifiersMode} from '|shared';
import {
  FileSettleDocumentDetailDialogData,
  FileSettleDocumentDetailDialogResult
} from '|modules/documents/modules/document-shared/components/file-settle-dialog/file-settle-model';
import {IczFormControl, IczFormGroup, IczValidators, TextLength} from '@icz/angular-form-elements';
import {injectModalData, injectModalRef} from '@icz/angular-modal';


@Component({
  selector: 'icz-file-settle-document-detail-dialog',
  templateUrl: './file-settle-document-detail-dialog.component.html',
  styleUrls: ['./file-settle-document-detail-dialog.component.scss'],
})
export class FileSettleDocumentDetailDialogComponent {
  protected modalRef = injectModalRef<Nullable<FileSettleDocumentDetailDialogResult>>();
  protected modalData = injectModalData<FileSettleDocumentDetailDialogData>();

  acceptableDataFileFormats = [DataFileFormatAcceptability.ACCEPTABLE, DataFileFormatAcceptability.RECOMMENDED, DataFileFormatAcceptability.COMPLEMENTARY];

  readonly EsslComponentsTableColumnSet = EsslComponentsTableColumnSet;
  readonly BusinessRuleName = BusinessRuleName;
  readonly VisibleClassifiersMode = VisibleClassifiersMode;

  form = new IczFormGroup({
    documentRefNumber: new IczFormControl<Nullable<string>>(null),
    documentSubject: new IczFormControl<Nullable<string>>(null, [IczValidators.maxLength(100)], undefined, TextLength.UNLIMITED),
    documentId: new IczFormControl<Nullable<number>>(null),
    documentTypeId: new IczFormControl<Nullable<number>>(null),
    components: new IczFormControl<Nullable<EsslComponentDto[]>>(null),
    excludedComponentIds: new IczFormControl<Nullable<number[]>>([]),
  });

  get preselectedComponents() {
    return this.form.value.components as EsslComponentDto[];
  }

  confirm() {
    const formValue = this.form.getRawValue();
    this.modalRef.close({
      documentId: formValue.documentId!,
      excludedComponentIds: formValue.excludedComponentIds!,
      documentTypeId: formValue.documentTypeId
    });
  }

  cancel() {
    this.modalRef.close();
  }

  ngOnInit(): void {
    this.form.get('documentId')!.setValue(this.modalData.documentId);
    this.form.get('documentTypeId')!.setValue(this.modalData.documentTypeId);
    this.form.get('components')!.setValue(this.modalData.preselectedComponents);
  }

  setExcludedComponentIds(ids: number[]) {
    this.form.get('excludedComponentIds')!.setValue(ids);
  }
}
